import { Button } from "react-bootstrap";
interface MyComponentProps {
  btnTitle: string;
  btnClass: string;
}
const ScheduleDemoButton: React.FC<MyComponentProps> = (props) => {
  return (
    <>
      <Button className={`primaryBtnDemo ${props.btnClass}`}>
        {props.btnTitle}
      </Button>
    </>
  );
};

export default ScheduleDemoButton;
