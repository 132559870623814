// blogSlice.ts
import { createSlice } from "@reduxjs/toolkit";

const blogSlice = createSlice({
  name: "blog",
  initialState: { blogs: [], currentBlog: null },
  reducers: {
    setBlogs: (state, action) => {
      state.blogs = action.payload;
    },
    setCurrentBlog: (state, action) => {
      state.currentBlog = action.payload;
    },
  },
});

export const { setBlogs, setCurrentBlog } = blogSlice.actions;
export default blogSlice.reducer;
