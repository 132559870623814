import { Box } from "@mui/material"
import BannerLowerSection from "../BannerLowerSection"
import DataCardContainer from "../Common/DataCardContainer"


const DataFeedApiSection = () => {
  return (
    <Box sx={{margin:"40px 0 0 0"}}>

    <BannerLowerSection  bannerSectionData ={ {title:"Revolutionize your decision making process.",subtitle:"Try our flagship product mPulse today!"}}/>

      <Box sx={{ margin:{lg:"60px 76.8px 0 76.8px",xs:"20px 20px 0 20px"}, display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
        <div className="headingCSS mar-btm-30px">
                  <h1> Data Feed &nbsp;
                    <span className="headingSpan">API</span>
                  </h1>
        </div>
              <p className="text-center p-tag-center">
                Seamlessly integrate our API and access real-time insights to fuel your brand's success
              </p>

      <DataCardContainer/>
      </Box>

    </Box>
      
    
    
  )
}

export default DataFeedApiSection