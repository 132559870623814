import { ReactElement, FC } from "react";
import Banner from "../components/Banner";
import CardSection from "../components/CardSection";
// import IndustryExpertiseSection from "../components/IndustryExpertiseSection";
import BannerHome from "../components/BannerHome";
import DataFeedApiSection from "../components/Sections/DataFeedApiSection";
// import { Box, Typography } from "@mui/material";
// import Slider from "../components/Slider";
// import FAQs from "../components/FAQs";

const Home: FC<any> = (): ReactElement => {
  return (
    <>
      {/* <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "whitesmoke",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Slider />
      </Box> */}
      <BannerHome />
      <Banner />
      <CardSection />
      <DataFeedApiSection/>
      {/* <IndustryExpertiseSection /> */}
      {/* <Typography variant="h3">Home</Typography> */}
      {/* <FAQs /> */}
    </>
  );
};

export default Home;
