import { FC, ReactElement } from "react";
import { Box, Hidden, Link } from "@mui/material";
import { Col, Container, Row, Image } from "react-bootstrap";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { footerRoutes } from "../../routes";
import BannerLowerSection from "../BannerLowerSection";
import facebook from "../../images/SocialIcons/Facebook.png";
import twitter from "../../images/SocialIcons/Twitter.png";
import linkedin from "../../images/SocialIcons/Linkdin.png";
import instagram from "../../images/SocialIcons/Instagram.png";
import locationIcon from "../../images/locationPngImage.png";
import mmatrixLogoBlack from "../../images/Footer/brand_logo_black.png";
import email from "../../images/email_Icon.png";

export const Footer: FC = (): ReactElement => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (scrollToPath: any) => {
    if (scrollToPath) {
      if (location.pathname !== scrollToPath) {
        window.scrollTo(0, 0);
        navigate(scrollToPath);
      }
    } else {
      if (location.pathname !== "/") {
        window.scrollTo(0, 0);
      }

      navigate("/");
    }
  };

  const handleLeadClick = () => {
    let email = "support@mmatrix.ai";
    let subject = "Inquiry about mPulse Product";
    let emailBody =
      "Dear Team mPulse,%0D%0A %0D%0A I am reaching out to express my interest in learning more about your product.%0D%0A %0D%0A Please provide me with these details and guide me how to get started? %0D%0A %0D%0A I eagerly await your response %0D%0A %0D%0A Thanks";
    document.location =
      "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
  };

  return (
    <Box
      className="footerBG"
      sx={{
        width: "100%",
        height: "auto",
        // backgroundColor: "primary.main",
        paddingTop: "1rem",
        // paddingBottom: "1rem",
      }}
    >
      <BannerLowerSection bannerSectionData ={ {title:"Empower Your Brand with Real-time Insights",subtitle:"Fuel your brand's success with our API today!"}} />
      <Hidden mdUp>
        <hr className="dividerFooter"></hr>
      </Hidden>
      <Container className="gutter-Spacing-0 addressContainer">
        <Row>
          <Col xs={12} sm={12} md={12} lg={4} className="footerDiv">
            <div>
              {/* <p className="pFooterDesc mar-btm-30px"> */}
              <p className="footerTitle">
                <Link
                  key="Home"
                  component={NavLink}
                  to="/"
                  color="transparent"
                  underline="none"
                  onClick={handleClick}
                >
                  <img
                    className="brandLogoFooter"
                    src={mmatrixLogoBlack}
                    alt="brand Logo"
                  />
                </Link>
              </p>
              {/* <h3 className="footerTitle mar-btm-30px">mMatrix</h3> */}
              <p className="pFooterDesc mar-btm-30px">
                At mMatrix, we believe that the future of successful businesses
                lies in their ability to leverage data effectively.
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={4}
            className="pad-lft-50px footerDiv"
            // data-aos="zoom-in-up"
            // data-aos-easing="linear"
            // data-aos-duration="1500"
          >
            <h3 className="footerTitle">Company</h3>
            <div className="footerTextColumn">
              {footerRoutes.map((page) => (
                <div className="footerLink">
                  <Link
                    key={`footer-${page.key}`}
                    component={NavLink}
                    to={page.path}
                    color="#333"
                    underline="none"
                    variant="button"
                    onClick={() => {
                      handleClick(page.path);
                    }}
                  >
                    {page.title}
                  </Link>
                </div>
              ))}
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={4}
            // data-aos="slide-left"
            // data-aos-easing="linear"
            // data-aos-duration="1500"
            className="footerDiv"
          >
            <h3 className="footerTitle">Contact Us</h3>

            {/* <div className="officeTimings mar-btm-25px">
              <Image
                className="mar-right-8px"
                src={timings}
                alt="First slide"
              />{" "}
              <span>
                {" "}
                MON - FRI : 10:00 - 19:00 IST
                <br />
                1800218898
              </span>
            </div> */}
            <div className="footerTextColumn">
              <div className="officeAddress mar-btm-25px">
                <Image
                  className="mar-right-8px"
                  src={locationIcon}
                  alt="First slide"
                />{" "}
                <span>
                  mMatrix Technology Inc. <br />
                  438 Alexandria Place Drive,<br></br>Apopka, FL 32712
                </span>
              </div>
              <div
                className="officeEMail mar-btm-25px"
                onClick={handleLeadClick}
              >
                <Image
                  className="mar-right-8px"
                  src={email}
                  alt="First slide"
                />{" "}
                <span onClick={handleLeadClick}>support@mmatrix.ai</span>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4}>
            <div className="socialLinks">
              <Image
                className="mar-right-8px socialIcon"
                src={facebook}
                alt="First slide"
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/profile.php?id=61550789277968"
                  );
                }}
              />
              <Image
                className="mar-right-8px socialIcon"
                src={twitter}
                alt="First slide"
                onClick={() => {
                  window.open("https://twitter.com/mPulse_mMatrix");
                }}
              />
              <Image
                className="mar-right-8px socialIcon"
                src={linkedin}
                alt="First slide"
                onClick={() => {
                  window.open("https://www.linkedin.com/company/mmatrix/");
                }}
              />
              <Image
                className="socialIcon"
                src={instagram}
                alt="First slide"
                onClick={() => {
                  window.open("https://www.instagram.com/mpulse_mmatrix/");
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <footer className="footerRights">
        &copy; {`${new Date().getFullYear()}`} mPulse | mMatrix. All rights
        reserved.
      </footer>
    </Box>
  );
};

export default Footer;
