import { useState } from "react";
import { Accordion, Card, Button, Collapse } from "react-bootstrap";
// import ScheduleDemoButton from "./ScheduleDemoButton";

const MyAccordion = () => {
  const [expandedIndex, setExpandedIndex] = useState(0);

  const handleToggle = (index: any) => {
    if (expandedIndex === index) {
      setExpandedIndex(0); // Collapse the current active accordion item
    } else {
      setExpandedIndex(index); // Expand the clicked accordion item
    }
  };

  return (
    <>
      <Accordion defaultActiveKey="0">
        <div className="mar-btm-20px">
          <Card.Header
            className={`accordionHead ${
              expandedIndex === 0 ? "activeAccodion" : ""
            } `}
          >
            <Button
              className="btnFaqsBlock"
              variant="link"
              onClick={() => handleToggle(0)}
              aria-expanded={expandedIndex === 0}
              aria-controls="accordion-item-0"
            >
              <span> What is mMatrix?</span>
              {expandedIndex === 0 ? (
                <i className="bi bi-caret-up-fill"></i>
              ) : (
                <i className="bi bi-caret-down-fill"></i>
              )}
            </Button>
          </Card.Header>
          <Collapse className="accordionContent" in={expandedIndex === 0}>
            <div id="accordion-item-0">
              <Card.Body className="faqText">
                mMatrix is a leading company that specializes in leveraging
                data, Machine learning, and AI to help businesses make smarter
                decisions and stay ahead of the competition.
              </Card.Body>
            </div>
          </Collapse>
        </div>

        <div className="mar-btm-20px">
          <Card.Header
            className={`accordionHead ${
              expandedIndex === 1 ? "activeAccodion" : ""
            } `}
          >
            <Button
              className="btnFaqsBlock"
              variant="link"
              onClick={() => handleToggle(1)}
              aria-expanded={expandedIndex === 1}
              aria-controls="accordion-item-1"
            >
              <span>What products or services does mMatrix offer?</span>
              {expandedIndex === 1 ? (
                <i className="bi bi-caret-up-fill"></i>
              ) : (
                <i className="bi bi-caret-down-fill"></i>
              )}
            </Button>
          </Card.Header>
          <Collapse className="accordionContent" in={expandedIndex === 1}>
            <div id="accordion-item-1">
              <Card.Body className="faqText">
                mMatrix offers cutting-edge solutions, including our flagship
                product mPulse for real-time sentiment analysis.
              </Card.Body>
            </div>
          </Collapse>
        </div>

        <div className="mar-btm-20px">
          <Card.Header
            className={`accordionHead ${
              expandedIndex === 2 ? "activeAccodion" : ""
            } `}
          >
            <Button
              className="btnFaqsBlock"
              variant="link"
              onClick={() => handleToggle(2)}
              aria-expanded={expandedIndex === 2}
              aria-controls="accordion-item-1"
            >
              <span>What industries does mMatrix cater to?</span>
              {expandedIndex === 2 ? (
                <i className="bi bi-caret-up-fill"></i>
              ) : (
                <i className="bi bi-caret-down-fill"></i>
              )}
            </Button>
          </Card.Header>
          <Collapse className="accordionContent" in={expandedIndex === 2}>
            <div id="accordion-item-2">
              <Card.Body className="faqText">
                mMatrix offers tailored solutions in industries like telecom,
                where our seasoned professionals understand the specific nuances
                and challenges.
              </Card.Body>
            </div>
          </Collapse>
        </div>

        <div className="mar-btm-20px">
          <Card.Header
            className={`accordionHead ${
              expandedIndex === 3 ? "activeAccodion" : ""
            } `}
          >
            <Button
              className="btnFaqsBlock"
              variant="link"
              onClick={() => handleToggle(3)}
              aria-expanded={expandedIndex === 3}
              aria-controls="accordion-item-1"
            >
              <span>
                What makes mMatrix stand out from other companies in the
                industry?
              </span>
              {expandedIndex === 3 ? (
                <i className="bi bi-caret-up-fill"></i>
              ) : (
                <i className="bi bi-caret-down-fill"></i>
              )}
            </Button>
          </Card.Header>
          <Collapse className="accordionContent" in={expandedIndex === 3}>
            <div id="accordion-item-2">
              <Card.Body className="faqText">
                At mMatrix, we stand out by combining our expertise in data,
                machine learning, and AI to provide innovative solutions that
                empower businesses to make smarter decisions and drive growth.
              </Card.Body>
            </div>
          </Collapse>
        </div>

        <div className="mar-btm-20px">
          <Card.Header
            className={`accordionHead ${
              expandedIndex === 4 ? "activeAccodion" : ""
            } `}
          >
            <Button
              className="btnFaqsBlock"
              variant="link"
              onClick={() => handleToggle(4)}
              aria-expanded={expandedIndex === 4}
              aria-controls="accordion-item-1"
            >
              <span>
                Can mMatrix customize its solutions to fit specific business
                needs?
              </span>
              {expandedIndex === 4 ? (
                <i className="bi bi-caret-up-fill"></i>
              ) : (
                <i className="bi bi-caret-down-fill"></i>
              )}
            </Button>
          </Card.Header>
          <Collapse className="accordionContent" in={expandedIndex === 4}>
            <div id="accordion-item-2">
              <Card.Body className="faqText">
                Absolutely! We understand that every business is unique. That’s
                why we offer tailored solutions to meet specific business
                requirements. Our team works closely with clients to understand
                their goals and design customized strategies and products
                accordingly.
              </Card.Body>
            </div>
          </Collapse>
        </div>

        <div className="mar-btm-20px">
          <Card.Header
            className={`accordionHead ${
              expandedIndex === 5 ? "activeAccodion" : ""
            } `}
          >
            <Button
              className="btnFaqsBlock"
              variant="link"
              onClick={() => handleToggle(5)}
              aria-expanded={expandedIndex === 5}
              aria-controls="accordion-item-1"
            >
              <span>Does mMatrix offer ongoing support and training?</span>
              {expandedIndex === 5 ? (
                <i className="bi bi-caret-up-fill"></i>
              ) : (
                <i className="bi bi-caret-down-fill"></i>
              )}
            </Button>
          </Card.Header>
          <Collapse className="accordionContent" in={expandedIndex === 5}>
            <div id="accordion-item-2">
              <Card.Body className="faqText">
                Yes, we provide comprehensive support and training to ensure our
                clients can maximize the value of our solutions. Our team is
                readily available to assist with any queries, provide guidance,
                and offer training sessions to ensure smooth implementation and
                usage of our products.
              </Card.Body>
            </div>
          </Collapse>
        </div>

        <div className="mar-btm-20px">
          <Card.Header
            className={`accordionHead ${
              expandedIndex === 6 ? "activeAccodion" : ""
            } `}
          >
            <Button
              className="btnFaqsBlock"
              variant="link"
              onClick={() => handleToggle(6)}
              aria-expanded={expandedIndex === 6}
              aria-controls="accordion-item-1"
            >
              <span>How can I schedule a demo with mMatrix?</span>
              {expandedIndex === 6 ? (
                <i className="bi bi-caret-up-fill"></i>
              ) : (
                <i className="bi bi-caret-down-fill"></i>
              )}
            </Button>
          </Card.Header>
          <Collapse className="accordionContent" in={expandedIndex === 6}>
            <div id="accordion-item-2">
              <Card.Body className="faqText">
                To schedule a demo and explore our products, simply fill out the
                form on our website with your name, email, contact details etc.,
                Our team will then get back to you promptly to arrange the demo.
              </Card.Body>
            </div>
          </Collapse>
        </div>
      </Accordion>

      {/* <ScheduleDemoButton btnTitle="See More" btnClass="purpleBtn" /> */}
    </>
  );
};

export default MyAccordion;
