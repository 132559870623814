import { Card, Col, Container, Row, Image } from "react-bootstrap";
import slide01 from ".././images/leverage_power_of_data.png";
import slide02 from ".././images/harness_the_potential_AI.png";
import slide03 from ".././images/drive_growth_with_ml.png";
import dotsGroup from '../images/Common/dotsGroup.svg'


const Banner = () => {


  const cardsData  = [{title:"Leverage the Power of Data",cardIcon:slide01, description:"Our data-driven solutions enable you to unlock the potential of your data. From data collection to advanced analytics, we equip you with the insights you need to make informed decisions"}
     ,{title:"Harness the Potential of Artificial Intelligence",cardIcon:slide02,
     description:"Our AI capabilities empower your business to optimize processes, predict trends, and enhance customer experiences. Embrace the power of artificial intelligence to stay ahead in a dynamic marketplace."},
     {title:"Drive Growth with Machine Learning",cardIcon:slide03,
     description:"With cutting-edge machine learning algorithms, we help you anticipate trends, understand customer behavior, and optimize your strategies for maximum impact."},
   ]


  return (
    <Container fluid className="bannerUpperlavender cardPadding">
       <img className="dotsGroupTopLeft" src={dotsGroup} alt="dots group"/>

      <div className="banner-overlay1" >
        <Container>
          <div
            className="headingCSS"
            data-aos="slide-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-once='true' 
          >
            <h1 className="mar-btm-30px">
              The Complete
              <span className="headingSpan"> Solution</span>
              {/* <span>Example Tagline Text</span> */}
            </h1>
          </div>
          <p  
            data-aos="slide-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-once='true'  
            className="cardDescription">
             mMatrix offers a cutting-edge product mPulse designed to maximize your potential and drive success in the digital age. Our solution empowers businesses to make informed decisions, uncover growth opportunities, and stay ahead of the competition.

          </p>
        </Container>
          <br />
        <Container className="cardsContainer">
          <Row>
            {
              cardsData.map((item,index) => 
              <Col xs={12} sm={12} md={12} lg={4} 
                data-aos="slide-up"
                data-aos-easing="ease-forwards"
                data-aos-duration={`${500+index*500}`}
                data-aos-once='true' 
              >
              {" "}
              <Card className="cardBanner">
                <Card.Body className="cardBody-2">
                  <div className="cardHeadDiv1 mar-btm-30px" >
                    <div className="cardIconCSS1">
                      <Image style={{ 'width': '100%', 'height': '270px', 'flexShrink': 0 }}
                        className="card-icon1"
                        src={item.cardIcon}
                        alt="First slide"
                      />
                    </div>
                    <br />
                    <Card.Title className="cardTitle1" style={{ textAlign: 'left' }}>
                      {item.title}
                    </Card.Title>
                  </div>
                  <Card.Text className="cardDesc pad-x-sm" style={{ textAlign: 'left' }}>
                    {item.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
              )
            }
          </Row>
        </Container>
      </div>
      <img className="dotsGroupBottomRight" src={dotsGroup} alt="dots group"/>
    </Container>
  );
};

export default Banner;
