const dev = {
mpulse_app:"https://dev-mpulse.mmatrix.ai",
mpulse_web:'https://dev-mpulse-web.mmatrix.ai',
}

const prod = {
    mpulse_app:"https://mpulse.mmatrix.ai",
    mpulse_web:'https://mpulse-web.mmatrix.ai',
}


const config = (process.env.NODE_ENV === "development" || document.location.origin.includes("dev"))  ?  dev : prod;

const configs = { // Add common config values here
  ...config,}

export default configs;