import { Card, Col, Container, Row } from "react-bootstrap";
import { Image } from "react-bootstrap";
import realTime from '../images/LandingPage/RealTime/manWithLaptop.png'
import graphChart from '../images/LandingPage/RealTime/graph.png'
import statChart from '../images/LandingPage/RealTime/stats.png';
import bottomLeftCircle from '../images/LandingPage/bottomLeft.png';
import topRightCircle from '../images/LandingPage/topRightCircle.png';
import slide03 from ".././images/mMatrix_compare_brand_image.png";
import manWithLaptop from "../images/LandingPage/FAQSection/withAllImagesCombined.png";
// import barGraph from "../images/LandingPage/FAQSection/horizontalChart.png";
// import dashboard from "../images/LandingPage/FAQSection/dashboard.png";
// import colorCircle from "../images/LandingPage/FAQSection/colorCircle.png";
// import pieChart from "../images/LandingPage/FAQSection/pieChart.png";
import icon1 from ".././images/realTime_icon-1.png";
import icon2 from ".././images/realTime_icon-3.png";
import icon3 from ".././images/realTime_icon-2.png";
import icon4 from ".././images/realTime_icon-4.png";
import clip from ".././images/mMatrix_Clips.png";
import thickSpring from "../images/Common/thickSpring.png";
import engageDataEmpowerBusiness from '../images/LandingPage/engageData.png'
import blueCircle from '../images/LandingPage/smallDotsCricle.png'
import ScheduleDemoButton from "./ScheduleDemoButton";
import FAQs from "./FAQs";
import { Link } from "react-router-dom";
import { Hidden } from "@mui/material";
import { MPULSE_WEB_URL } from "../Constants/Constant";

const CardSection = () => {

  
  return (
    <div>
      <div className="unlease_power_mpulse_bg">
        <Container>
          <div
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-delay="500"
            data-aos-anchor-placement="top-bottom"
            data-aos-once='true' 
          >
            <div className="headingCSS mar-btm-30px">
              <h1>
                Unleash the Power of
                <span className="headingSpan"> mPulse</span>
                {/* <span>Example Tagline Text</span> */}
              </h1>
            </div>
            <p className="text-center p-tag-center">
              Gain valuable insights into how customers perceive your products,
              services, and overall brand experience
            </p>
          </div>
        </Container>
        <Container>
          <Row className="padding-none row-gutter-x-y-none alignCards">
            <Col
              xs={12}
              sm={12}
              md={8}
              lg={6}
              className="padding-zero flexAlignCenterNew"
            >
              <div
                className="container-real-time"
                data-aos="slide-left"
                // data-aos-anchor-placement="top-bottom"
                data-aos-easing="ease-in-out"
                data-aos-duration="1200"
                // data-aos-delay="500"
                data-aos-once='true' 
              >
                <img className="realTimeChart shake-Y" src={graphChart} alt='graph chart'/>
                <img className="realStatShow shake" src={statChart} alt='graph chart'/>
                <img className="realTimeEllipse1 rotate-XY" src={bottomLeftCircle} alt='ellipse chart'/>
                <img className="realTimeEllipse2 rotate-XY" src={topRightCircle} alt='ellipse2 chart'/>
                <Image
                  className="d-block w-100"
                  src={realTime}
                  alt="First slide"
                  fluid
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} className="">
              <div
                data-aos="slide-up"
                // data-aos-anchor-placement="top-bottom"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                // data-aos-delay="500"
                className="height-100 paddingLeft"
                data-aos-once='true' 
              >
                <Card.Body className="cardCenter">
                  <Card.Title className="cardTitle mar-btm-20px">
                    Real Time <span>Sentiment Analysis</span>
                  </Card.Title>
                  <Card.Text className="cardDescription mar-btm-30px p-tag-left">
                    Experience how customers perceive your products, services,
                    and overall brand experience
                  </Card.Text>
                  <Row className="padding-none margin-none row-gutter-x-y-none">
                    <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
                      <div className="sectionHeadDiv mar-btm-20px">
                        <div className="sectionIconCSS">
                          <Image
                            className="cardIcon"
                            src={icon1}
                            alt="First slide"
                          />
                        </div>
                        <div className="cardDescription">
                          Understand Market Perception
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
                      <div className="sectionHeadDiv mar-btm-20px">
                        <div className="sectionIconCSS">
                          <Image
                            className="cardIcon"
                            src={icon2}
                            alt="First slide"
                          />
                        </div>
                        <div className="cardDescription">
                          Monitor social media mentions
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
                      <div className="sectionHeadDiv mar-btm-20px">
                        <div className="sectionIconCSS">
                          <Image
                            className="cardIcon"
                            src={icon3}
                            alt="First slide"
                          />
                        </div>
                        <div className="cardDescription">Identify Trends</div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
                      <div className="sectionHeadDiv mar-btm-20px">
                        <div className="sectionIconCSS">
                          <Image
                            className="cardIcon"
                            src={icon4}
                            alt="First slide"
                          />
                        </div>
                        <div className="cardDescription">Visualize dynamic data</div>
                      </div>
                    </Col>
                  </Row>
                  <Card.Text className="cardDescription mar-btm-30px p-tag-left">
                    Anticipate your customers needs, and build strong
                    relationships that drive loyalty.
                  </Card.Text>
                  <Link
                    key="Home"
                    to={`${MPULSE_WEB_URL}`}
                    color="transparent"
                    //target="_blank"
                  >
                    <ScheduleDemoButton
                      btnTitle="Get Started"
                      btnClass="purpleBtn"
                    />
                  </Link>
                </Card.Body>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="dataCollectionBG">
        <Container className="">
          <Row className="padding-none margin-none row-gutter-x-y-none cardPadding">
            <Col xs={12} sm={12} md={12} lg={6} className="">
              <div
                data-aos="slide-up"
                // data-aos-anchor-placement="top-bottom"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                // data-aos-delay="500"
                className="height-100 paddingLeft"
                data-aos-once='true' 
              >
                <Card.Body className="cardCenter">
                  <Card.Title className="cardTitle mar-btm-20px">
                    <span>Engage your Data.</span> Empower your Business.
                  </Card.Title>
                  <Card.Text className="cardDescription mar-btm-30px p-tag-left">
                   
                    Unlock the power of actionable insights from your data and
                    gain a competitive edge.
                  </Card.Text>
                  <Row className="padding-none margin-none row-gutter-x-y-none">
                    <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
                      <div className="mar-btm-20px">
                        
                        <div className="cardDescription p-tag-left">
                        <ul>
                          <li>Innovate and adapt swiftly to changing market dynamics</li>
                        </ul>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
                      <div className="mar-btm-20px">
                        
                        <div className="cardDescription p-tag-left">
                        <ul>
                          <li> Leverage intelligent recommendations for strategic
                          planning </li>
                        </ul>                    
                        </div>                       
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
                      <div className="mar-btm-20px">
                        <div className="cardDescription p-tag-left">
                        <ul>
                          <li> Mitigate risks by anticipating market shifts and
                          customer behavior </li>
                        </ul>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} className="padding-zero">
                      <div className=" mar-btm-20px">
                        <div className="cardDescription p-tag-left">
                        <ul>
                          <li> Identify true potential of your data </li>
                        </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Card.Text className="cardDescription mar-btm-30px p-tag-left">
                    Elevate your decision-making process with real-time
                    insights, driving your business towards success.
                  </Card.Text>
                 <Hidden mdUp>
                 <Row className="padding-none margin-none row-gutter-x-y-none alignCards">
                 <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={6}
                    className="padding-zero flexAlignCenterNew"
                  >
                    {" "}
                    <div
                      data-aos="zoom-in-down"
                      // data-aos-anchor-placement="top-bottom"
                      data-aos-easing="ease-in-out"
                      data-aos-duration="1500"
                      // data-aos-delay="500"
                      data-aos-once='true' 
                      className="container-rotation"
                    >
                       <img className="animationCircle rotate-XY" src={blueCircle} alt="outer circle"/>
                        <Image
                          className="d-block w-100 pivotImage"
                          src={engageDataEmpowerBusiness}
                          alt="First slide"
                          fluid
                          style={{ objectFit: "cover" }}
                        />
                    </div>
                  </Col>
                  </Row>
                 </Hidden>
                  <Link
                    key="Home"
                    to={`${MPULSE_WEB_URL}`}
                    color="transparent"
                    //target="_blank"
                  >
                    <ScheduleDemoButton
                      btnTitle="Get Started"
                      btnClass="purpleBtn"
                    />
                  </Link>
                </Card.Body>
              </div>
            </Col>
            <Hidden mdDown>
             <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="padding-zero flexAlignCenterNew"
            >
              {" "}
              <div
                data-aos="zoom-in-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
                data-aos-delay="500"
                data-aos-once='true' 
                className="container-rotation"
              >
                <img className="animationCircle" src={blueCircle} alt="outer circle"/>
                <Image
                  className="d-block w-100 pivotImage"
                  src={engageDataEmpowerBusiness}
                  alt="First slide"
                  fluid
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            </Hidden>
            
          </Row>
          {/* <Image className="clipCSS" src={clip} alt="First slide" /> */}
        </Container>
      </div>
      <div className="dashboards_reportings_BG">
        <Container>
          <Row className="padding-none margin-none row-gutter-x-y-none cardPadding alignCards">
          <Hidden mdDown>
               <img src={thickSpring} className="graphSectionCurl shake-Y" alt="spring curl" />
          </Hidden> 
            <Col
              xs={12}
              sm={12}
              md={8}
              lg={6}
              className="padding-zero flexAlignCenterNew mar-btm-30px"
            >
              {" "}
              <div
                data-aos="slide-left"
                // data-aos-anchor-placement="top-bottom"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                // data-aos-delay="500"
                style={{ position: "relative" }}
                data-aos-once='true' 
              >    
              <Hidden mdUp>
               <img src={thickSpring} className="graphSectionCurl shake-Y" alt="spring curl" />
              </Hidden>              
                <Image
                  className="d-block w-100"
                  src={slide03}
                  alt="First slide"
                  fluid
                  style={{ objectFit: "cover" }}
                />
                <Image className="clipCSS shake-Y" src={clip} alt="First slide" />
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} className="mar-top-30px">
              <div
                data-aos="slide-up"
                // data-aos-anchor-placement="top-bottom"
                data-aos-easing="ease-in-out"
                // data-aos-delay="500"
                data-aos-duration="1200"
                className="height-100 paddingLeft"
                data-aos-once='true' 
              >
                <Card.Body
                  className="cardCenter"
                  style={{ justifyContent: "flex-start" }}
                >
                  <Card.Title className="cardTitle">
                    Compare Your Brand To <span>The Competition</span>
                  </Card.Title>
                  <hr style={{width:'95%'}}></hr>
                  <Card.Text className="cardDescription">
                    <div className="sectionHeadDiv mar-btm-30px">
                      <div className="cardTitle">
                        Cutting-Edge Products for Data-Driven Decision-Making
                      </div>
                    </div>
                    <ul>
                      <li>
                        Unearth underlying trends to adapt strategies and stay
                        one step ahead in the market
                      </li>
                      <li>
                        Customize dashboards for real-time data visualization
                        and interaction
                      </li>
                      <li>
                        Early detection of issues for proactive resolution
                      </li>
                      <li>
                        Leverage the latest machine learning algorithms for
                        intelligent insights.
                      </li>
                    </ul>
                  </Card.Text>
                  <Link
                    key="Home"
                    to={`${MPULSE_WEB_URL}`}
                    color="transparent"
                    //target="_blank"
                  >
                    <ScheduleDemoButton
                      btnTitle="Get Started"
                      btnClass="purpleBtn"
                    />
                  </Link>
                </Card.Body>
              </div>
            </Col> 
          </Row>  
        </Container>
      </div>
      <div className="faqBG">
        <Container>
          <div >
            <div className="headingCSS">
              <h1>Frequently Asked Questions</h1>
            </div>

          </div>
        </Container>
        <Container>
          <Row className="padding-none margin-none row-gutter-x-y-none pad-top-md alignCards">
            <Col xs={12} sm={12} md={12} lg={6} className="">
              <div
                data-aos="slide-up"
                // data-aos-anchor-placement="top-bottom"
                data-aos-easing="ease-in-out"
                data-aos-duration="1500"
                // data-aos-delay="500"
                className="height-100 paddingLeft paddingRight"
                data-aos-once='true' 
              >
                <FAQs />
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} className="padding-zero">
              <div
                data-aos="slide-right"
                // data-aos-anchor-placement="top-bottom"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
                className="container-FAQ-image"
                // data-aos-delay="500"
                data-aos-once='true' 
              > 
              {/* <div className="">
                <img className="FAQGraph shake" src={barGraph} alt="outer circle"/>
                <img className="dashboardFAQ shake-Y" src={dashboard} alt="outer circle"/>
                <img className="FAQColorCircle" src={colorCircle} alt="outer circle"/>
                <img className="FAQPieChart shake" src={pieChart} alt="outer circle"/>
              </div> */}
              
                <Image
                  className="d-block"
                  src={manWithLaptop}
                  alt="First slide"
                  fluid
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CardSection;
