import { Card, Col, Container, Row, Image } from "react-bootstrap";

import slide01 from ".././images/Integrated.svg";
import slide02 from ".././images/intuitive.svg";
import slide03 from ".././images/Flexible.svg";
import slide04 from ".././images/Efficient.svg";
import dotsGroup from "../images/Common/dotsGroup.svg"

const Banner = () => {

  const cardsData  = [{title:"Integrated",cardIcon:slide01, description:"Our solutions seamlessly combine data analytics, machine learning and AI to deliver comprehensive and actionable insights"}
     ,{title:"Intuitive",cardIcon:slide02,
     description:" With user-friendly interfaces and easy-to-understand reports, our tools empower your team to make informed decisions confidently."},
     {title:"Flexible",cardIcon:slide03,
     description:"We understand that every business is unique. Our customizable solutions are designed to adapt to your specific needs, ensuring that you can tailor your marketing strategies to meet your business goals."},
     {title:"Efficient",cardIcon:slide04,
     description:"Maximize your marketing ROI by optimizing resource utilization with our efficient solutions. We streamline workflows and eliminate bottlenecks, allowing you to focus on what matters most – engaging your customers."},
   ]
  return (
    <Container fluid className="bannerUpper cardPadding">
       <img className="dotsGroupTopLeft" src={dotsGroup} alt="dots group"/>

      <div className="banner-overlay1">
        <Container>
          <div
            className="headingCSS"
            data-aos="slide-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="700"
            data-aos-once='true' 
          >
            <h1 className="mar-btm-30px">
            We Empower Businesses with Data-Driven <br />
              <span className="headingSpan"> Marketing Solutions</span>
            </h1>
          </div>
          <p data-aos="slide-up"
                data-aos-easing="ease-in-out"
                data-aos-duration="800"
                data-aos-once='true'  className="">
          We are passionate about empowering businesses with data-driven marketing solutions. Our integrated, intuitive, flexible, and efficient approach helps you build a customer-centric marketing strategy that drives success in today's dynamic marketplace.
          </p>
        </Container>

        <Container >
          <Row className="alignItemsCenter">
            {
              cardsData.map((item,index) => 
              <Col xs={12} sm={12} md={6} xl={3} lg={6} className="cardSection"
              data-aos="slide-up"
              data-aos-easing="ease-forwards"
              data-aos-duration={`${500+index*500}`}
              data-aos-once='true' 
              >
              <Card className="cardBanner">
                    <Card.Body className="cardBody">
                      <div className="cardHeadDiv">
                        <div className="cardIconCSS">
                          <Image
                            className="card-icon"
                            src={item.cardIcon}
                            alt="First slide"
                          />
                        </div>
                        <Card.Title className="cardTitle">
                          &nbsp;&nbsp;{item.title}
                        </Card.Title>
                      </div>
                      <Card.Text className="cardDesc">
                        {item.description}
                      </Card.Text>
                    </Card.Body>
                  </Card>
              </Col>)
            }
          </Row>
        </Container>
      </div>
    </Container>

  );
};

export default Banner;
