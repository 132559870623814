// src/store/index.js
import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./reducers";
import blogSliceReducer from "./slices/blogSlice";

const store = configureStore({
  reducer: {
    blogSlice: blogSliceReducer,
  },
});

export default store;
