import { Box,} from "@mui/material"
import heroImage from "../../images/FeedApi/heroImage.png"


const FeedApiHeroSection = () => {

   
   
 return (
        <Box className="feedApi--hero-section">
            <Box sx={{ margin:{lg:"0 76.8px 0 76.8px",xs:"0",},height:"100%",minHeight:"410px", display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",maxWidth:"1440px",minHeight:"410px",flexDirection:{md:"row",xs:"column"}}}>

                    <Box sx={{display:"flex",width:{xs:"80%",md:"50%"},height:"100%",flexDirection:"column",
                      margin:{xs:"3rem 0 0 0", md:"1rem 0px 0px 64px",lg:"1rem 0px 0px 0px"},alignItems:{md:"flex-start",xs:"center"}}}>
                    
                        <h1 className="bannerTitle text-align-left mar-btm-30px heroTitleFeedApi">
                          Data Feed API
                        </h1>
                        <h2 className="bannerSubTitle subtitle--feedapi">
                         Seamlessly integrate our API and access real-time Social Listening insights to fuel your brand's success.
                        </h2>

                    </Box>

                    <Box sx={{display:"flex",width:"50%",justifyContent:{lg:"flex-end",sm:"space-around"},alignItems:{lg:"flex-end"}}}>
                     <img className="heroImage--feedApi" src={heroImage}  alt="feed api hero" />
                    </Box>

                </Box>
            </Box>
        </Box>
    
    )

 }

export default FeedApiHeroSection