import {Grid,} from "@mui/material";
import aspectIcon from "../../images/LandingPage/CardsIcon/aspect_analytics.svg";
import sentimentIcon from "../../images/LandingPage/CardsIcon/sentiment_analysis.svg";
import engagementIcon from "../../images/LandingPage/CardsIcon/engagement_metrics.svg";
import competitiveIcon from "../../images/LandingPage/CardsIcon/competitive_ranking_insights.svg";
import mediaAttachmentsIcon from "../../images/LandingPage/CardsIcon/media_attachments.svg";
import userfollowerMetricsIcon from "../../images/LandingPage/CardsIcon/user_follower_metrics.svg";
import DataCard from "./DataCard";


const DataCardContainer = ({data}:any) => {

    const cardsData  = [
                        {title:"Sentiment Analytics",cardIcon:aspectIcon, 
                        description:"Integrate our API to Gain insights into the sentiment surrounding your brand, products, and industry. Our API delivers sentiment analysis in real-time, allowing you to stay ahead of the curve and adapt your strategies accordingly."}
                        ,{title:"Aspect Analytics",cardIcon:sentimentIcon,
                        description:"Dive deeper into the nuances of conversations with aspect analytics. Identify key themes and topics driving discussions, enabling you to tailor your messaging and initiatives more effectively."},
                        {title:"Engagement Metrics",cardIcon:engagementIcon,
                        description:"Track engagement metrics such as likes, comments, and shares across various social media platforms. Understand how your audience is interacting with your content and identify opportunities for increased engagement."},
                        {title:"Competitive Ranking and Insights",cardIcon:competitiveIcon, 
                        description:"Stay ahead of the competition with comprehensive competitive ranking and insights. Benchmark your performance against industry peers and uncover actionable insights to stay ahead in the market."},
                        {title:"Media Attachments",cardIcon:mediaAttachmentsIcon,
                        description:"Access media attachments including images, videos, and links shared in social media conversations. Understand the visual context behind discussions and leverage multimedia content to drive engagement."},
                        {title:"User and Follower Metrics",cardIcon:userfollowerMetricsIcon, 
                        description:"Gain valuable insights into your audience demographics, user behaviors, and follower metrics. Understand who your audience is, where they're located, and how they're engaging with your brand across social channels."},
                       ]

  return (         
            <Grid sx={{mt:2.5,maxWidth:"1340px"}} container spacing={{lg:2, md:2,xs:2,sm:2}}>
                
               { cardsData?.map(item =>                 
                <Grid sx={{display:"flex",flexDirection:"column",alignItems:"center"}} item lg={4} sm={6} xs={12}>
                        <DataCard
                         cardIcon={item.cardIcon}
                         title={item.title}
                         description={item.description}
                        />
                       
                </Grid> )
                }
            </Grid>
          )
}

export default DataCardContainer