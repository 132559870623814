import React, { FC, ReactElement } from "react";
import {
  Box,
  Link,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Hidden,
} from "@mui/material";
import { Image } from "react-bootstrap";
import { routes, navLoginRoutes } from "../routes";
import { NavLink, useLocation } from "react-router-dom";
import slide01 from ".././images/mMatrixLogo.png";
import MenuIcon from ".././images/menu_Icon.svg";

const Navbar: FC = (): ReactElement => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const location = useLocation();

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box
      className={`${
        location.pathname === "/"
          ? "navBarCss"
          : (location.pathname === "/about" || location.pathname === "/feedApi" )
          ? "navBarCssAbout"
          : location.pathname === "/blogs"
          ? "navBarCssBlogs"
          : "navBarCss"
      }`}
      sx={{
        width: "100%",
        height: "auto",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters className="navBarBorder">
          {/* For Mobiles */}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <Image className="mMatrixLogo" src={slide01} alt="Logo" />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              "justify-content": "flex-end",
            }}
          >
            <IconButton
              className="whiteColor"
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <img src={MenuIcon} className="navIcon" alt="menu" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routes.map((page) => {
                if (page.key === "blog-single-route") {
                  return null;
                } else {
                  return (
                    <Link
                      key={`mob-${page.key}`}
                      component={NavLink}
                      to={page.path}
                      color="black"
                      underline="none"
                      variant="button"
                    >
                      <MenuItem onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{page.title}</Typography>
                      </MenuItem>
                    </Link>
                  );
                }
              })}

              {navLoginRoutes.map((page) => (
                <Link
                  key={`mob-${page.key}`}
                  component={NavLink}
                  to={page.path}
                  color="black"
                  underline="none"
                  variant="button"
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          {/* End For Mobiles */}

          {/* <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },

            }}
          >
            <Link
              key="Home"
              component={NavLink}
              to="/"
              color="transparent"
              underline="none"
            >
              <Image className="mMatrixLogo" src={slide01} alt="Logo" />
            </Link>
          </Typography> */}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <Hidden smDown>
              <Link
                className="logoLinkdiv"
                key="Home-Logo"
                component={NavLink}
                to="/"
                color="transparent"
                underline="none"
              >
                <Image className="mMatrixLogo" src={slide01} alt="Logo" />
              </Link>
            </Hidden>

            {/* <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 0,
              display: { xs: "none", md: "flex" },

            }}
          >
            <Link
              key="Home"
              component={NavLink}
              to="/"
              color="transparent"
              underline="none"
            >
              <Image className="mMatrixLogo" src={slide01} alt="Logo" />
            </Link>
          </Typography> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "1rem",
                width: `calc(20rem + 50vw)`,
              }}
            >
              {routes.map((page) => {
                if (page.key === "blog-single-route") {
                  return null; // Skip rendering for this route
                } else {
                  return (
                    <div key={page.key} className="mdMenuLeft">
                      <Link
                        key={`${page.key}`}
                        component={NavLink}
                        to={page.path}
                        color="white"
                        underline="none"
                        variant="button"
                      >
                        {page.title}
                      </Link>
                    </div>
                  );
                }
              })}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginLeft: "1rem",
                minWidth: "calc(16rem + 4vw)",
              }}
            >
              {navLoginRoutes.map((page) => (
                <div className="mdMenuRight">
                  <Link
                    key={page.key}
                    component={NavLink}
                    to={page.path}
                    color="white"
                    underline="none"
                    variant="button"
                    target="_blank"
                  >
                    {page.title}
                  </Link>
                </div>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </Container>
      {/* <hr style={{ width: "85%" }}></hr> */}
    </Box>
  );
};

export default Navbar;
