import { ReactElement, FC, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Image } from "react-bootstrap";
import blogDate from ".././images/blogDate.png";
import { Link } from "react-router-dom";
import "./Blogs.css";

const BlogsCard: FC<any> = (props): ReactElement => {
  useEffect(() => {
    const truncateText = (element: any, maxCharacters: number) => {
      const originalText: string = element.textContent || "";
      if (originalText.length > maxCharacters) {
        const truncatedText = originalText.substring(0, maxCharacters) + "...";
        element.textContent = truncatedText;
      }
    };
    const textElements = document.querySelectorAll(".blogTitle");
    textElements.forEach((element) => {
      return truncateText(element, 50); // Adjust the character limit as needed
    });
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Link
        to={`/blogs/${props.slug}`}
        color="transparent"
        style={{ textDecoration: "none" }}
        onClick={handleClick}
      >
        <div className={`blogsCard ${props.className ? props.className : ""}`}>
          <Card.Body>
            <div className="blogImage">
              <Image
                style={{ width: "100%", height: "auto", flexShrink: 0 }}
                className="card-icon1"
                src={props.blogSrc}
                alt="Slide"
              />
            </div>
            <div className="blogInfoSection">
              {/* <div className="infoProfile flexCenterDiv">
                {" "}
                <Image
                  style={{ height: "auto", flexShrink: 0 }}
                  className="card-icon1"
                  src={props.blogProfileImg}
                  alt="Slide"
                />
                <span> {props.profileName} </span>
              </div> */}
              <div className="infoProfile flexCenterDiv">
                <Image
                  style={{ height: "auto", flexShrink: 0 }}
                  className="card-icon1"
                  src={blogDate}
                  alt="Slide"
                />
                <span> {props.date} </span>
              </div>
            </div>
            <Card.Text className="blogTitle">{props.blogText}</Card.Text>
          </Card.Body>
        </div>{" "}
      </Link>
    </>
  );
};

export default BlogsCard;
