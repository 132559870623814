import { Card,Image } from "react-bootstrap";

interface iconDataProps {
  cardIcon:string,
  title:string,
  description:string,
}


const DataCard = ({cardIcon,title,description}:iconDataProps) => {
  return (
    <Card
    className="cardBanner">
    <Card.Body className="cardBody">
    <div className="cardHeadDiv">
        <div className="cardIconCSS ">
        <Image
            className="card-icon white-icon"
            src={cardIcon}
            alt="First slide"
        />
        </div>
        <Card.Title className="cardTitle">
        {title}
        </Card.Title>
    </div>
    <Card.Text className="cardDesc">
        {description}
    </Card.Text>
    </Card.Body>
 </Card>
    
  )
}

export default DataCard