
import { Box } from "@mui/material";
import DataCardContainer from "../components/Common/DataCardContainer";
import FeedApiHeroSection from "../components/FeedApi/FeedApiHeroSection";

const FeedApi = () => {


  return (
    <>

    <FeedApiHeroSection/>
    <Box sx={{ margin:{lg:"60px 76.8px 0 76.8px",xs:"20px 20px 0 20px"}, display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>

        <div className="headingCSS mar-btm-30px feedApi--cardSection--heading" style={{width:"100%",maxWidth:"1340px",}}>
            <h1 style={{textAlign:"start"}}> Why Integrate our  &nbsp;
                <span className="headingSpan">API</span> ?
            </h1>
        </div>
        <DataCardContainer/>
    </Box>
    
    </>
  )
}

export default FeedApi