// api.js
import axios from "axios";
const BASE_URL = "https://api-prod1-mpulse.mmatrix.ai";

export const fetchBlogs = async (blogs: any) => {
  try {
    const response = await axios.get(`${BASE_URL}/${blogs}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchBlogById = async (blogId: any) => {
  try {
    const response = await axios.get(`${BASE_URL}/posts/${blogId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
